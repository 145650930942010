// Copyright @ 2023 Greendzine Technologies Pvt ltd. All rights reserved. For Internal use only.

import React from "react";

import "./footer.css";

const Footer = function () {
  return (
    <div className="foot">
      <div className="footer-text">
        ©2023, Greendzine Technologies, Pvt. Ltd. All rights reserved{" "}
      </div>
    </div>
  );
};

export default Footer;
