// Copyright @ 2023 Greendzine Technologies Pvt ltd. All rights reserved. For Internal use only.

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuth: false
}

const authSlice = createSlice({
    name:"auth",
    initialState,
    reducers: {
        setAuth: (state, action) => {
            state.isAuth = action.payload;
        }
    }
})

export default authSlice.reducer;
export const {setAuth} = authSlice.actions;