import React, { useState } from "react";
import "./body.css";
import indexLogo from "../../assets/index_logo.png";
import analytics from "../../assets/AnalyticsLogoSingle.svg";
import Gdt from "../../assets/MOPTro.png";
import AppDownload from "../PopUp/AppDownload";
import { useNavigate } from "react-router-dom/dist";

const Body = () => {
  const naviagte = useNavigate();
  const [showPopup, setPopUp] = useState(false);
  const [selectedApp, setSelecetedApp] = useState("");
  const webApps = [
    {
      logo: indexLogo,
      name: "Index",
    },
    {
      logo: analytics,
      name: "Analytics",
    },
    // {
    //   logo: Gdt,
    //   name: "MOPTro Firmware",
    // },
    // {
    //   logo: Gdt,
    //   name: "MOPTro Bluetooth Firmware",
    // },
    {
      logo: Gdt,
      name: "Analytics Picking",
    },
    {
      logo: Gdt,
      name: "Paka",
    },
    // {
    //     logo: indexLogo,
    //     name: "Index "
    // },
    // {
    //     logo: analytics,
    //     name: "Analytics"
    // },
    // {
    //     logo: indexLogo,
    //     name: "Index "
    // },
    // {
    //     logo: analytics,
    //     name: "Analytics"
    // },
    // {
    //     logo: indexLogo,
    //     name: "Index "
    // },
    // {
    //     logo: analytics,
    //     name: "Analytics"
    // }
  ];

  const mobApps = [
    {
      logo: Gdt,
      name: "Connect",
    },
    {
      logo: Gdt,
      name: "Pick",
    },
    {
      logo: Gdt,
      name: "Voice Based Picking",
    },
  ];

  // const handleDownload = (e) => {
  //   // if (e === "Connect" || e === "Pick") {
  //   //   setSelecetedApp(e);
  //   //   setPopUp(!showPopup);
  //   // } else {
  //     handleNavigate(e);
  //   // }
  // };

  const handleNavigate = (e) => {
    if (e === "Moptro Firmware") {
      window.open(
        "https://workdrive.zoho.com/folder/2douy02ccd25e08bc46269e9947e85d425708",
        "_blank",
        "noopener noreferrer"
      );
    }
    else if (e === "Voice Based Picking") {
      window.open(
        "https://workdrive.zoho.com/file/t7wsb1b9f54cb4e5840aa96b5f1bb9456dec4",
        "_blank",
        "noopener noreferrer"
      );
    }
    else if (e === "Connect") {
      window.open(
        "https://workdrive.zoho.com/file/lpjd44eb6ca38eb90450da341415266091944",
        "_blank",
        "noopener noreferrer"
      );
    }
    else if (e === "Pick") {
      window.open(
        "https://workdrive.zoho.com/file/4nhti39fdb93d8d1244e19ff3f2aa673ea05e",
        "_blank",
        "noopener noreferrer"
      );
    }
    else {
      window.open(
        "https://workdrive.zoho.com/folder/lpjd46e40faec99e64bcea962bd433280e450",
        "_blank",
        "noopener noreferrer"
      );
    }
  };

  const handleClose = (e) => {
    setPopUp(false);
  };

  const handleNavigation = (e) => {
    switch (e) {
      case "Index":
        window.open(
          "https://index.w2hconnect.com/app/",
          "_blank",
          "noopener noreferrer"
        );
        break;
      case "Analytics":
        window.open(
          "https://analytics.w2hconnect.com/app/",
          "_blank",
          "noopener noreferrer"
        );
        break;
      case "MOPTro Firmware":
        window.open(
          "https://workdrive.zoho.com/folder/2douy02ccd25e08bc46269e9947e85d425708",
          "_blank",
          "noopener noreferrer"
        );
        break;
      case "MOPTro Bluetooth Firmware":
        window.open(
          "https://workdrive.zoho.com/folder/2douycae49605c9414d6a8c01a98d93eb374b",
          "_blank",
          "noopener noreferrer"
        );
        break;
      case "Analytics Picking":
        window.open(
          "https://analytics.zoho.com/open-view/1471251000002644177",
          "_blank",
          "noopener noreferrer"
        );
      case "Paka":
        window.open(
          "https://www.greendzine.co.in/PAKA-simulation/analytics_basic.php",
          "_blank",
          "noopener noreferrer"
        );
      default:
        break;
    }
  };

  return (
    <div className="dash-body">
      {showPopup && (
        <AppDownload
          isOPen={showPopup}
          close={handleClose}
          option={selectedApp}
        />
      )}
      <div className="main-content">
        <div style={{ height: "100%" }} className="dash-box">
          <div className="app-lists" style={{ height: "100%", width: "80%" }}>
            <div style={{ fontWeight: "400" }}>Web Applications</div>
            <hr style={{ opacity: "0.5", width: "80%" }} />
            <div className="web-app">
              {webApps.map((app, idx) => (
                <div
                  className="apps"
                  onClick={() => handleNavigation(app.name)}
                  key={idx}
                >
                  <img width={70} src={app.logo} />
                  <div className="app-name">{app.name}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="app-lists" style={{ height: "100%", width: "80%" }}>
            <div style={{ fontWeight: "400" }}>Mobile Applications</div>
            <hr style={{ opacity: "0.5", width: "80%" }} />
            <div className="web-app">
              {mobApps.map((app, idx) => (
                <div
                  className="apps"
                  onClick={() => handleNavigate(app.name)}
                  key={idx}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => handleNavigate(app.name)}
                    width={70}
                    src={app.logo}
                  />
                  <div
                    className="app-name"
                    onClick={() => handleNavigate(app.name)}
                  >
                    {app.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
