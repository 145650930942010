import React from "react";
import "./sideNav.css"
import {NavLink} from "react-router-dom";

const SideNav = (props) => {
    return(
        <div className="sidenav-main">
            <NavLink to={props.path}>
                <li id = "list_item" className={props.class ? "main-home" : "a"}>
                    {props.img && <img src={props.img} width = "25"  alt = ""/>}
                    <h3>{props.name}</h3>
                </li>
            </NavLink>
        </div>
    )
} 

export default SideNav;