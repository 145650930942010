import React from "react";
import "./popUp.css"
import qrcodeConnect from "../../assets/QRpng.png"
import qrcodePick from "../../assets/pickApkQrcode.png"


const AppDownload = ({close, option}) => {
    //console.log(option);
    const handleClick = () => {
        close(false);
    }
    let image;

    if (option === "Pick") {
       // console.log("Helloo")
        image = qrcodePick;
    }
    else image = qrcodeConnect;
    return (
        <div onClick={handleClick} className="download-popup">
            <div className="download-close">
                <button onClick={handleClick} className="close-button">
                    Close
                </button>
            </div>
            <img src={image} style={{height: "80%", borderRadius: "15px"}}/>
        </div>
    )
}

export default AppDownload;