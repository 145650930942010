import {React, useState} from "react";
import "./head.css";
import { useNavigate} from "react-router-dom";

import w2h from "../../assets/W2H.png"
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/slices/authSlice"; 
import profile from "../../assets/profile.png"
import Loader from "../Loader/Loader";
 
const Head = () => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [appOpen, setAppOpen] = useState(false);
    function handleClick() {
        setLoader(false);
        navigate("/login");
        dispatch(setAuth(false));
        const loggedInUser = localStorage.getItem("authenticated");
        if (loggedInUser) {
            localStorage.clear();
        }
    }        
    const [dropdown, setDropdown] = useState(false);
  
    const handleIconClick = () => {
        setDropdown(!dropdown);
        
    };

    const handleOptionClick = (option) => {
        if (option === "Logout") {
          localStorage.clear(); 
          setLoader(true);         
          setTimeout(handleClick, 3000);
        }
        setDropdown(false);
      };
    return (
        <div className="head">
            {loader && <Loader/>}
            <img src={w2h} alt="w2h" className="w2h-image"/>
            <h2 style={{fontWeight: "800"}}>W2H Connect</h2>
            <div className="icons">
                <div className={appOpen ? "home-app-open" : "home-app"}>
                </div>
                {false && <div className="profile"><img src={profile} style={{height:"25px"}} alt="" onClick={handleIconClick} /></div>}
                {dropdown && (
                    <div style={{zIndex: "10"}}  className="drop">
                    <div onClick={() => handleOptionClick('Logout')} >Log Out</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Head;