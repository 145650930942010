import React from "react";
import "./loader.css"

const Loader = () => {
    return (
        <div id="main-alert-box" >
            <span className="loader"></span>
        </div>
    )
}

export default Loader;