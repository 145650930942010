import React, {useEffect} from "react"; 
import Head from "../../components/Head/Head";
import Footer from "../../components/Footer/Footer";
import SideNav from "../../components/SideNav/SideNav";
import Body from "../../components/DashboardBody/Body";
import home from "../../assets/home.png"


const DashBoard = () => {

    const fields = [
        {path: '/app/analytics/dashboard', class: true, img : home, name : "Home"},
      ];
    return (
        <>
        <Head />
        <div className='sidebar'>
            <ul className='list'>
            {fields.map((field, idx) => (
                <SideNav key= {idx} path={field.path} class={field.class} img = {field.img} name = {field.name} />
            ))}
            </ul>
            </div>
        <Body/>
        <Footer />
        </>
    )
}

export default DashBoard;